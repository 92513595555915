.squareContainer {
  position: relative;
}

.square {
  border: 0.1px solid white;
  border-radius: 99px;
  list-style-type: none;
  animation: appearDesktop 0.5s forwards ease;
}
@media only screen and (max-width: 720px) {
  .square {
    animation: appearMobile 0.5s forwards ease;
  }
}
.squareMonth {
  border: 0.1px solid white;
  border-radius: 99px;
  list-style-type: none;
  animation: appearDesktopMonth 0.5s forwards ease;
}
@media only screen and (max-width: 720px) {
  .squareMonth {
    animation: appearMobileMonth 0.5s forwards ease;
  }
}

.squareYear {
  border: 0.1px solid white;
  border-radius: 99px;
  list-style-type: none;
  animation: appearDesktopYear 0.5s forwards ease;
}
@media only screen and (max-width: 720px) {
  .squareYear {
    animation: appearMobileYear 0.5s forwards ease;
  }
}

.number {
  position: absolute;
  left: -1rem;
  bottom: -1vh;
  font-size: 8px;
}

@keyframes appearMobile {
  from {
    width: 0.1vh;
    height: 0.1vh;
  }
  to {
    width: 0.4vh;
    height: 0.4vh;
  }
}

@keyframes appearDesktop {
  from {
    width: 0.1vh;
    height: 0.1vh;
  }
  to {
    width: 0.9vh;
    height: 0.9vh;
  }
}

@keyframes appearMobileMonth {
  from {
    width: 0.1vh;
    height: 0.1vh;
  }
  to {
    width: 1vh;
    height: 1vh;
  }
}

@keyframes appearDesktopMonth {
  from {
    width: 0.1vh;
    height: 0.1vh;
  }
  to {
    width: 1.4vh;
    height: 1.4vh;
  }
}

@keyframes appearMobileYear {
  from {
    width: 0.1vh;
    height: 0.1vh;
  }
  to {
    width: 3vh;
    height: 3vh;
  }
}

@keyframes appearDesktopYear {
  from {
    width: 0.1vh;
    height: 0.1vh;
  }
  to {
    width: 7vh;
    height: 7vh;
  }
}
